import React from "react";
import SEO from "../components/seo";

const Index = () => {
    return (
        <div>
            <SEO title="Studio M Nedvěd" />
            <article className="article">404 - Doh'!</article>
        </div>
    );
};
export default Index;
